'use client'

import { Button } from '@/components/ui/button'
import { Slider } from '@/components/ui/slider'
import { globalAudioPlayer } from '@/lib/AudioStreamPlayer'
import { exampleAudios } from '@/lib/example-audios'
import { useVoiceStore } from '@/lib/state'
import {
    Download,
    Pause,
    Play,
    RewindIcon as Rewind10,
    Volume2,
} from 'lucide-react'

if (
    false && //
    process.env.NODE_ENV === 'development'
) {
    const addExampleChunks = async () => {
        globalAudioPlayer.title = 'Example Audio'
        globalAudioPlayer.description = 'An example audio track'
        for (let audioUrl of exampleAudios) {
            const response = await fetch(audioUrl)
            await globalAudioPlayer.addChunk(audioUrl)
        }
        return
    }
    addExampleChunks()
}

import { createPortal } from 'react-dom'

export function AudioPlayerWithWebAudio() {
    if (typeof window === 'undefined') {
        return null
    }

    return createPortal(<AudioPlayerWithWebAudioInner />, document.body)
}

export default AudioPlayerWithWebAudio

export function AudioPlayerWithWebAudioInner() {
    const { currentTime, description, duration, isPlaying, title, volume } =
        useVoiceStore((x) => x.player)

    const togglePlayPause = () => {
        if (isPlaying) {
            globalAudioPlayer.pause()
        } else {
            globalAudioPlayer.play()
        }
    }

    const handleSeek = (newPosition: number[]) => {
        globalAudioPlayer.seek(newPosition[0] / 100)
    }

    const handleVolumeChange = (newVolume: number[]) => {
        const volumeValue = newVolume[0] / 100
        globalAudioPlayer.updateVolume(volumeValue)
    }

    const handleDownload = () => {
        globalAudioPlayer.download()
    }

    const handleReset = () => {
        globalAudioPlayer.reset()
    }

    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60)
        const seconds = Math.floor(time % 60)
        return `${minutes}:${seconds.toString().padStart(2, '0')}`
    }
    if (!title) {
        return null
    }

    return (
        <div className='fixed bottom-0 left-0 right-0 border-t bg-white  dark:border-neutral-800 dark:bg-neutral-950'>
            <div className='flex h-[80px] items-center justify-between px-4'>
                {/* Playback Controls */}
                <div className='flex items-center gap-4'>
                    <Button
                        variant='ghost'
                        size='icon'
                        className='text-neutral-500 hover:text-neutral-950 dark:text-neutral-400 dark:hover:text-neutral-50'
                        onClick={togglePlayPause}
                    >
                        {isPlaying ? (
                            <Pause className='h-5 w-5' />
                        ) : (
                            <Play className='h-5 w-5' />
                        )}
                    </Button>
                    <Button
                        variant='ghost'
                        size='icon'
                        className='text-neutral-500 hover:text-neutral-950 sm:hidden dark:text-neutral-400 dark:hover:text-neutral-50'
                        onClick={() =>
                            globalAudioPlayer.restart()
                        }
                    >
                        <Rewind10 className='h-5 w-5' />
                    </Button>
                </div>

                {/* Track Info and Progress */}
                <div className='flex flex-1 items-center justify-center sm:justify-start gap-4 px-4'>
                    <div className=' min-w-0 items-center gap-3 hidden sm:flex'>
                        <div
                            className='h-10 w-10 rounded bg-gradient-to-br from-blue-400 to-purple-600'
                            aria-hidden='true'
                        />
                        <div className='min-w-0 flex-1'>
                            <div className='truncate max-w-[160px] text-sm font-medium dark:text-neutral-200'>
                                {title}
                            </div>
                            <div className='truncate max-w-[160px] text-xs text-neutral-500 dark:text-neutral-400 '>
                                {description}
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-1 items-center gap-2'>
                        <div className='text-xs tabular-nums text-neutral-500 hidden sm:block dark:text-neutral-400'>
                            {formatTime(currentTime)}
                        </div>
                        <Slider
                            value={[(currentTime / duration) * 100 || 0]}
                            max={100}
                            step={1}
                            onValueChange={handleSeek}
                            className='[&_[role=slider]]:h-4 [&_[role=slider]]:w-4 dark:[&_[role=slider]]:bg-neutral-200 dark:[&_[role=track]]:bg-neutral-700'
                        />
                        <div className='text-xs tabular-nums text-neutral-500 hidden sm:block dark:text-neutral-400'>
                            {formatTime(duration)}
                        </div>
                    </div>
                </div>

                {/* Volume and Actions */}
                <div className='flex items-center gap-4'>
                    <div className='items-center gap-2 hidden sm:flex'>
                        <Button
                            variant='ghost'
                            size='icon'
                            className='text-neutral-500 hover:text-neutral-950 dark:text-neutral-400 dark:hover:text-neutral-50'
                        >
                            <Volume2 className='h-5 w-5' />
                        </Button>
                        <Slider
                            value={[volume * 100]}
                            max={100}
                            step={1}
                            onValueChange={handleVolumeChange}
                            className='w-[8rem] opacity-80 [&_[role=slider]]:h-4 [&_[role=slider]]:w-4 dark:[&_[role=slider]]:bg-neutral-600 dark:[&_[role=track]]:bg-neutral-800'
                        />
                    </div>
                    {/* <Button
                        variant='ghost'
                        size='icon'
                        className='text-neutral-500 hover:text-neutral-950 hidden sm:inline-flex dark:text-neutral-400 dark:hover:text-neutral-50'
                        onClick={handleReset}
                    >
                        <Heart className='h-5 w-5' />
                    </Button> */}
                    <Button
                        variant='ghost'
                        size='icon'
                        className='text-neutral-500 hover:text-neutral-950 dark:text-neutral-400 dark:hover:text-neutral-50'
                        onClick={handleDownload}
                    >
                        <Download className='h-5 w-5' />
                    </Button>
                </div>
            </div>
        </div>
    )
}
