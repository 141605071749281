import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '@/components/ui/table'
import { globalAudioPlayer } from '@/lib/AudioStreamPlayer'
import { useVoiceStore } from '@/lib/state'
import { cn } from '@/lib/utils'
import ColorHash from 'color-hash'
import { allVoices, uniqueLanguages, uniqueProviders, VoiceData } from 'db/data'
import { PauseIcon, PlayIcon, Search } from 'lucide-react'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'

const colorHash = new ColorHash()

function PreviewButton({ voice }: { voice: VoiceData }) {
    const previewForVoiceId = useVoiceStore(
        (state) => state.player.previewForVoiceId,
    )
    const isPlaying = previewForVoiceId === voice.uniqueId
    const Icon = isPlaying ? PauseIcon : PlayIcon
    const provider = voice.provider
    const handleClick = async (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (isPlaying) {
            globalAudioPlayer.pause()
            useVoiceStore.setState((x) => ({
                player: {
                    ...x.player,
                    previewForVoiceId: '',
                },
            }))
            return
        }
        useVoiceStore.setState((x) => ({
            player: {
                ...x.player,
                previewForVoiceId: voice.uniqueId,
            },
        }))

        const src =
            voice?.['previewUrl'] ||
            `/voice-previews/${provider}/${voice.id}.mp3`

        globalAudioPlayer.reset()
        globalAudioPlayer.title = `${voice.name}, ${voice.provider}`
        globalAudioPlayer.description = 'Voice Preview'
        globalAudioPlayer.emit()

        await globalAudioPlayer.addChunk(src)
        await globalAudioPlayer.play()
        useVoiceStore.setState((x) => ({
            player: {
                ...x.player,
                previewForVoiceId:
                    x.player.previewForVoiceId === voice.uniqueId
                        ? ''
                        : x.player.previewForVoiceId,
            },
        }))
    }

    return (
        <Button
            size='sm'
            variant='ghost'
            className='h-8 w-8 p-0'
            onClick={handleClick}
        >
            <Icon className='h-4 w-4' />
        </Button>
    )
}

export function VoiceTable({}) {
    const router = useRouter()
    const selectedVoice = useVoiceStore((state) =>
        allVoices.find((x) => x.uniqueId === state.selectedVoiceUniqueId),
    )
    const selectedLanguage = useVoiceStore((state) => state.selectedLanguage)
    const selectedProvider = useVoiceStore((state) => state.selectedProvider)
    const [filterValue, setFilterValue] = useState<string>('')

    const filteredItems = useMemo(() => {
        let filtered = allVoices

        if (selectedLanguage !== 'all') {
            filtered = filtered.filter(
                (voice) => voice.language === selectedLanguage,
            )
        }
        if (selectedProvider !== 'all') {
            filtered = filtered.filter(
                (voice) => voice.provider === selectedProvider,
            )
        }
        if (filterValue) {
            filtered = filtered.filter(
                (voice) =>
                    voice.name
                        ?.toLowerCase()
                        ?.includes(filterValue.toLowerCase()) ||
                    voice?.accent
                        ?.toLowerCase()
                        ?.includes(filterValue.toLowerCase()) ||
                    voice?.provider
                        ?.toLowerCase()
                        ?.includes(filterValue.toLowerCase()),
                // || voice.category
                // ?.toLowerCase()
                // .includes(filterValue.toLowerCase()),
            )
        }
        return filtered
    }, [selectedLanguage, filterValue, selectedProvider])

    return (
        <div className='space-y-4 h-full'>
            <div className='flex  dark:bg-gray-950/30 px-8 -mx-4 z-10 isolate rounded-lg backdrop-blur-lg py-4 sticky top-0 items-center gap-4'>
                <Select
                    value={selectedLanguage}
                    onValueChange={(value) =>
                        useVoiceStore.setState({ selectedLanguage: value })
                    }
                >
                    <SelectTrigger className='w-[180px]'>
                        <SelectValue placeholder='Select language' />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value='all'>All Languages</SelectItem>
                        {uniqueLanguages.map((lang) => (
                            <SelectItem key={lang} value={lang}>
                                {lang}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
                <Select
                    value={selectedProvider}
                    onValueChange={(value) =>
                        useVoiceStore.setState({ selectedProvider: value })
                    }
                >
                    <SelectTrigger className='w-[180px]'>
                        <SelectValue placeholder='Select provider' />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value='all'>All Providers</SelectItem>
                        {uniqueProviders.map((provider) => (
                            <SelectItem key={provider} value={provider}>
                                {provider}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
                <div className='relative w-full max-w-sm'>
                    <div className='absolute inset-y-0 left-0 flex items-center pl-2'>
                        <Search className='size-[16px] text-muted-foreground' />
                    </div>
                    <Input
                        placeholder='Search...'
                        className='pl-8 h-8'
                        value={filterValue}
                        onChange={(e) => setFilterValue(e.target.value)}
                    />
                </div>
            </div>

            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead className='w-[50px]'></TableHead>
                        <TableHead className='w-[50px]'></TableHead>
                        <TableHead>Name</TableHead>
                        <TableHead>Language</TableHead>
                        <TableHead>Accent</TableHead>
                        <TableHead>Category</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {filteredItems.map((voice) => (
                        <TableRow
                            key={voice.uniqueId}
                            className={cn(
                                'cursor-pointer hover:bg-muted',
                                selectedVoice?.uniqueId === voice.uniqueId &&
                                    'bg-muted',
                            )}
                            onClick={() => {
                                useVoiceStore.setState({
                                    selectedVoiceUniqueId: voice.uniqueId,
                                    isVoiceSheetOpen: false,
                                })
                                globalAudioPlayer.reset()
                            }}
                        >
                            <TableCell>
                                <PreviewButton voice={voice} />
                            </TableCell>
                            <TableCell>
                                <div
                                    className='w-8 h-8 rounded-md'
                                    style={{
                                        background: colorHash.hex(voice.name),
                                    }}
                                />
                            </TableCell>
                            <TableCell className='font-medium'>
                                {voice.name}, {voice.provider}
                            </TableCell>
                            <TableCell>{voice.language}</TableCell>
                            <TableCell>{voice.accent || '-'}</TableCell>
                            <TableCell>
                                {/* <Chip
                                    className='text-xs font-medium'
                                    style={{
                                        backgroundColor: colorHash.hex(
                                            voice.category || '',
                                        ),
                                        color: '#fff',
                                    }}
                                >
                                    {voice.category || '-'}
                                </Chip> */}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

function Chip({
    children,
    className = '',
    style = {},
}: {
    children: React.ReactNode
    className?: string
    style?: React.CSSProperties
}) {
    return (
        <span
            className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${className}`}
            style={style}
        >
            {children}
        </span>
    )
}
